function greet() {
    alert("Hello!");
}

function buildExample() {
    const svg = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg'
    );
    svg.setAttribute('width', 960);
    svg.setAttribute('height', 500);
    document.body.appendChild(svg);
    
    const rect = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'rect'
    );
    svg.setAttribute('width', 100);
    svg.setAttribute('height', 100);
    svg.appendChild(rect);
}